body,
html,
#root {
  height: 100%;
  width: 100%;
}

body {
  overflow: hidden;
}

#root {
  font-size: 16px;
}

@media only screen and (max-width: 786px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 516px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 458px) {
  html {
    font-size: 10px;
  }
}
@media only screen and (max-width: 381px) {
  html {
    font-size: 9px;
  }
}

html {
  --font-xl: 3rem;
  --font-lg: 2.5rem;
  --font-md: 2rem;
  --font-sm: 1.5rem;
  --font-xs: 1rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a700aa;
  border: solid 3px white;
  border-radius: 10px;
}
